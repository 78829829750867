import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"

const Product = styled(Link)`
  color: #000;
  margin-bottom: 40px;
  &:hover{
    text-decoration: none;
    h2{
      text-decoration: underline;
    }
  }
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`

const ProductTitle = styled.h2`
  margin: 0 0 6px;
  font-size: 32px;
`

const ProductPrice = styled.span`
  font-weight: 300;
  font-size: 18px;
`

const ProductImg = styled(Img)`
  display: block;
  margin: 0 0 10px;
  width: 100%;
`


function ProductTemplate({ data }) {
  return (
    <Product to={`/merchandise/${data.slug}`}>
      <ProductImg
        fluid={data.images[0].fluid}
        alt={data.images[0].title}
      ></ProductImg>
      <ProductTitle>{data.title}</ProductTitle>
      <ProductPrice>€{data.price.toFixed(2)}</ProductPrice>
    </Product>
  )
}

export default ProductTemplate
