import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Product from "../components/Product"
import Triangle from "../components/styles/Triangle"
import Title from "../components/styles/Title"

const ProductsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 31px;
  @media (max-width: 768px) {
    column-gap: 15px;
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 414px) {
    column-gap: 0;
    grid-template-columns: 1fr;
  }
`

function MerchPage({ data }) {
  const products = data.allContentfulProduct.nodes;
  return (
    <Layout>
      <Seo title="Merchandise | komm schon Alter" description="Get your shine on with our komm schon Alter merchandise | t-shirts, sweaters, socks, refillable water bottles" keywords="Merchandise, t-shirt, sweater, socks, refillable water bottles, Das Festival, club night, Amsterdam, house, techno" />
      <div style={{ maxWidth: "960px", margin: "0 auto" }}>
        <Title>Merch</Title>
        <ProductsWrapper>
          {products.map((node, i) => (
            <Product data={node} key={i} />
          ))}
        </ProductsWrapper>
      </div>
      <Triangle color="#bbaad8"></Triangle>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulProduct {
      nodes {
        title
        slug
        sizes {
            id
            label
        }
        images {
          title
            fluid(maxWidth: 300) {
                ...GatsbyContentfulFluid_withWebp
            }
        }
        price
      }
    }
  }
`

export default MerchPage
